<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <b-row>
      <b-col cols="12">
        <b-card class="">
          <h4 class="text-primary">
            <feather-icon icon="PlusSquareIcon" size="20"/>
            Add Category Property Group
          </h4>

          <b-row>
            <b-media class="my-2 col-12">
              <validation-observer
                  ref="refFormObserver"
              >
                <b-row>
                  <b-col cols="12" md="6">
                    <language-picker
                        @getLanguage="setLanguageId"
                    ></language-picker>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group
                        class="mb-2"
                        label="Name"
                        label-for="blog-content"
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            id="blog-content"
                            v-model="CPG.name"
                            :state="errors.length > 0 ? false:null"
                        ></b-form-input>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group
                        class="mb-2"
                        label="Priority"
                        label-for="priority"
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            id="priority"
                            v-model="CPG.priority"
                            :state="errors.length > 0 ? false:null"
                        ></b-form-input>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-button
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        class="mt-2 mb-sm-0 mr-0 col-12 col-md-1"
                        variant="primary"
                        @click="addCPG"
                    >
                      Add
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-media>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <h4 class="m-2">Category Property Groups list</h4>
          <b-table
              ref="refUserListTable"
              :fields="myTableColumns"
              :items="pageData"
              :sort-by.sync="sortBy"
              bordered
              class="position-relative"
              empty-text="Nothing found!"
              primary-key="id"
              responsive
              show-empty
              striped
          >
            <template #cell(id)="data">
              <a target="_blank">
                <span class="text-muted ml-2">#{{ data.item.id }}</span>
              </a>
            </template>

            <template #cell(operation)="data">
              <div class="d-flex align-items-center justify-content-start">
                <add-edit-delete-button
                    :data="generateObjectForAddEditRemoveButton(data.item)"
                    :languageList="languageList"
                    :hasProperties="true"
                    :isBlogCategory="false"
                    :handelWithModal="true"
                    :routerPropertiesName="'apps-categories-CategoryProperties-id'"
                    :routerEditName="'pages-posts-categories-edit'"
                    @renderDeleteModal="renderDeleteModal"
                    @renderCreateModal="renderCreateModal"
                    @renderEditModal="renderEditModal"
                ></add-edit-delete-button>
              </div>
            </template>

            <template #cell(sub)="data">
              <router-link :to="`/apps/categories/CategoryProperties/${data.item.id}`">
                <feather-icon
                    size="20"
                    icon="LogInIcon"
                    style="cursor: pointer"
                    class="text-primary"
                />
              </router-link>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <!-- Pagination -->
              <b-col
                  class="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap"
                  cols="12"
                  sm="12"
              >
                <b-pagination
                    v-model="currentPage"
                    :per-page="count"
                    :total-rows="totalCount"
                    class="mb-2 mb-md-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>

                <b-col
                    class="d-flex align-items-center justify-content-start justify-content-md-end mb-2 mb-md-0"
                    cols="12"
                    md="2"
                >
                  <label>Count</label>
                  <v-select
                      v-model="count"
                      :clearable="false"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      class="per-page-selector d-inline-block mx-50 w-50"
                  />
                </b-col>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
        id="modal-create"
        title="Add CPG with other language"
        cancelTitle="Cancel"
        centered
        ok-title="Add"
        no-close-on-backdrop
        @cancel="closeCreateModal"
        @ok="addCPG"
    >
      <b-form-group
          class="mt-2"
          label="title"
      >
        <b-form-input
            v-model="CPG.name"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          class="mt-2"
          label="title"
      >
        <b-form-input
            v-model="CPG.priority"
        ></b-form-input>
      </b-form-group>
    </b-modal>

    <b-modal
        title="Edit CPG"
        id="modal-edit"
        cancelTitle="Cancel"
        centered
        ok-title="Edit"
        no-close-on-backdrop
        @cancel="closeEditModal"
        @ok="updateData()"
    >
      <b-form-group
          class="mt-2"
          label="title"
      >
        <b-form-input
            v-model="selectedToEdit.name"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          class="mt-2"
          label="priority"
      >
        <b-form-input
            v-model="selectedToEdit.priority"
        ></b-form-input>
      </b-form-group>
    </b-modal>

    <b-modal
        id="modal-delete"
        cancelTitle="Cancel"
        centered
        ok-title="Delete"
        @ok="deleteData()"
    >
      <span>Are you sure?</span>
    </b-modal>

  </b-overlay>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs
} from 'bootstrap-vue'
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Helper from "@/libs/Helper";
import {required} from '@validations';
import {
  CategoryPropertyGroupCreate, CategoryPropertyGroupDelete, CategoryPropertyGroupGet,
  CategoryPropertyGroupGetAll, CategoryPropertyGroupGetBy, CategoryPropertyGroupUpdate,
} from "@/libs/Api/ProductCategory";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import AddEditDeleteButton from "@/views/components/utils/AddEditDeleteButton.vue";
import {GetAllLangs} from "@/libs/Api/Language";

export default {
  title: 'Categories property Group',
  data() {
    return {
      required,
      languageList:[],
      overlay: false,
      pageData: [],
      CPG: {
        languageId: null,
        name: null,
        hint: 'foo',
        productCategoryId:+this.$route.params.id,
        mainId: '',
        priority: 0,
      },
      perPageOptions: [10, 20, 30, 40, 50],
      currentPage: 1,
      sortBy: '',
      count: 10,
      totalCount: null,
      selectedToDelete: null,
      selectedToEdit: {},
      myTableColumns: [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'name',
          label: 'Title',
          sortable: true
        },
        {
          key: 'priority',
          label: 'Priority',
          sortable: true
        },
        {
          key: 'operation',
          label: 'Operation'
        },
        // {
        //   key: 'sub',
        //   label: 'Properties'
        // },
      ],
      selectedDataId: null,
    }
  },
  async created() {
    await this.getData();
    await this.getAllLanguages();
  },
  watch: {
    currentPage: function (nv, ov) {
      this.getData();
    },
    count: function (nv, ov) {
      this.getData();
    },
  },
  methods: {
    async getData() {
      let _this = this;
      _this.overlay = true;

      let data = {
        ProductCategoryId:_this.$route.params.id,
        // languageId:1,
        PageNumber: _this.currentPage,
        Count: _this.count,
      }

      let categoryPropertyGroupGetBy = new CategoryPropertyGroupGetBy(_this);
      categoryPropertyGroupGetBy.setParams(data);
      await categoryPropertyGroupGetBy.fetch(function (content) {
        _this.overlay = false;
        _this.pageData = content.data.data;
        _this.totalCount = content.data.total;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async addCPG() {
      let _this = this;
      Helper.validateForm(_this, 'refFormObserver', async () => {
        _this.overlay = true;
        // let formData = Helper.makeFormData(_this.CPG);
        let categoryPropertyGroupCreate = new CategoryPropertyGroupCreate(_this);
        categoryPropertyGroupCreate.setParams(_this.CPG);
        await categoryPropertyGroupCreate.fetch((content) => {
          _this.overlay = false;
          _this.CPG = {
            languageId: 1,
            name: null,
            hint: 'foo',
            productCategoryId:+_this.$route.params.id,
            mainId: '',
            priority: 0,
          };
          _this.getData();
        }, (error) => {
          _this.overlay = false;
          console.log(error)
        });
      })
    },
    async updateData() {
      let _this = this;
      if (_this.selectedToEdit.name.length < 1) {
        return _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            variant: 'danger',
            text: `CPG name is required!`,
          },
        })
      }
      _this.showOverlay = true;
      // let formData = Helper.makeFormData(_this.selectedToEdit);
      let categoryPropertyGroupUpdate = new CategoryPropertyGroupUpdate(_this);
      categoryPropertyGroupUpdate.setParams(_this.selectedToEdit);
      await categoryPropertyGroupUpdate.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Opperation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.showOverlay = false;
        _this.selectedToEdit = {};
        _this.getData();
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async deleteData() {
      let _this = this;
      _this.overlay = true;

      let categoryPropertyGroupDelete = new CategoryPropertyGroupDelete(_this);
      categoryPropertyGroupDelete.setParams({id:this.selectedToDelete.id});
      await categoryPropertyGroupDelete.fetch(function (content) {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.selectedToDelete = null;
        _this.getData();
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async getCPG(id){
      let _this = this;
      _this.showOverlay = true;

      let categoryPropertyGroupGet = new CategoryPropertyGroupGet(_this);
      categoryPropertyGroupGet.setParams({id:id})
      await categoryPropertyGroupGet.fetch((content) => {
        _this.showOverlay = false;
        _this.selectedToEdit = content.data.data;
      },(e)=>{
        _this.showOverlay = false;
        console.log(e)
      })
    },
    generateObjectForAddEditRemoveButton(data) {
      return {
        id: data.id,
        languageId: data.languageId,
        otherLanguages: data.otherLanguages || [],
        mainId: data.mainId,
      };
    },
    renderDeleteModal(data) {
      this.selectedToDelete = JSON.parse(JSON.stringify(data));
      this.$bvModal.show("modal-delete");
    },
    async renderEditModal(data) {
      if (!data.isOtherLanguage){
        let idx = this.pageData.findIndex(e => e.id === data.id);
        this.selectedToEdit = JSON.parse(JSON.stringify(this.pageData[idx]));
      }else {
        await this.getCPG(data.id)
      }
      setTimeout(()=>{
        this.$bvModal.show("modal-edit");
      },500)
    },
    renderCreateModal(data) {
      this.CPG.languageId = data.languageId;
      this.CPG.mainId = data.mainId;
      this.$bvModal.show("modal-create");
    },
    async getAllLanguages() {
      try {
        let requestParams = {
          count: 100,
          pageNumber: 1,
        };
        if (this.searchCommand) {
          requestParams["searchCommand"] = this.searchCommand;
        }
        this.isLoading = true;
        let _this = this;
        let getAllLangs = new GetAllLangs(_this);

        getAllLangs.setRequestParam(requestParams);
        await getAllLangs.fetch((response) => {
          if (response.isSuccess) {
            _this.languageList = response.data.languages;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    clickOnInput(id) {
      let input = document.getElementById(id);
      input.click();
    },
    setLanguageId(language) {
      this.CPG.languageId = language.languageId;
    },
    closeCreateModal(){
      this.CPG = {
        id: null,
        languageId: null,
        mainId: null,
        name: null,
      };
    },
    closeEditModal(){
      this.selectedToEdit = {};
    }
  },
  components: {
    AddEditDeleteButton,
    LanguagePicker,
    BModal,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BTable,
    BPagination,
    ValidationObserver, ValidationProvider,
  },
}
</script>

<style>
.modal-header > .close {
  display: none !important;
}
</style>